<template>
    <!-- Main Content -->
    <div id="content">

        <!-- End of Topbar -->

        <!-- Begin Page Content -->
        <div class="container-fluid pt-4">

            <!-- Page Heading -->
            <div class="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 class="h3 mb-0 text-gray-800">Tableau de bord: <span style="font-weight: 700">{{ serviceName }}</span></h1>
            </div>

            <!-- Content Row -->
            <div class="row">

                <!-- Earnings (Monthly) Card Example -->
                <div class="col-xl-3 col-md-6 mb-4">
                    <div class="card border-left-primary shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                        Revenu (Année)</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{ service ? moneyFormat(service.yearIncome) : `- ${devise}`}}</div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Revenue (Monthly) Card Example -->
                <div class="col-xl-3 col-md-6 mb-4">
                    <div class="card border-left-success shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-success text-uppercase mb-1">
                                        Revenu (Mois)</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{ service ? moneyFormat(service.monthIncome) : `- ${devise}`}}</div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Earnings (Monthly) Card Example -->
                <div class="col-xl-3 col-md-6 mb-4">
                    <div class="card border-left-info shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-info text-uppercase mb-1">
                                        Revenu (Semaine)</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{ service ? moneyFormat(service.weekIncome) : `- ${devise}`}}</div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Pending Requests Card Example -->
                <div class="col-xl-3 col-md-6 mb-4">
                    <div class="card border-left-warning shadow h-100 py-2">
                        <div class="card-body">
                            <div class="row no-gutters align-items-center">
                                <div class="col mr-2">
                                    <div class="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                        Revenu (Aujourd'hui)</div>
                                    <div class="h5 mb-0 font-weight-bold text-gray-800">{{ service ? moneyFormat(service.todayIncome) : `- ${devise}`}}</div>
                                </div>
                                <div class="col-auto">
                                    <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Content Row -->

            <div class="row">

                <!-- Area Chart -->
                <div class="col-12">
                    <div class="card shadow mb-4">
                        <!-- Card Header - Dropdown -->
                        <div
                            class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Evolution</h6>
                            <div class="dropdown no-arrow">
                                <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                </a>
                                <div class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                                    aria-labelledby="dropdownMenuLink">
                                    <div class="dropdown-header">Dropdown Header:</div>
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                </div>
                            </div>
                        </div>
                        <!-- Card Body -->
                        <div class="card-body">
                            <div class="chart-area">
                                <dashboard-chart :type="chartType" :data="chartData" :currency="devise"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" v-if="roomGroups">

                <!-- Area Chart -->
                <div class="col-12">
                    <div class="card shadow mb-4">
                        <!-- Card Header - Dropdown -->
                        <div
                            class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                            <h6 class="m-0 font-weight-bold text-primary">Chambres</h6>
                        </div>
                        <!-- Card Body -->
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6" v-for="group in roomGroups" :key="group._id">
                                    {{ `${group.name} (${group.price} ${devise})` }}
                                    <hr>
                                    <div class="row" v-if="group.rooms.length">
                                        <div class="col-6 room" v-for="room in group.rooms" :key="room._id">
                                            <span class="btn" :class="room.busy ? 'btn-danger' : 'btn-success'" @click="showRoomInfo(room)">{{ room.name }}</span> <span class="mr-2">{{ room.busy ? 'Occupée' : 'Libre' }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" v-if="service?.sales">

            <!-- Area Chart -->
            <div class="col-12">
                <div class="card shadow mb-4">
                    <!-- Card Header - Dropdown -->
                    <div
                        class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 class="m-0 font-weight-bold text-primary">10 Meilleures ventes</h6>
                    </div>
                    <!-- Card Body -->
                    <div class="card-body">
                        <div>
                            <div class="row">
                                <div class="col-sm-6 col-xs-12 mb-2">
                                    <label>Période</label>
                                    <select class="form-control" @change="updatePeriod">
                                        <option v-for="period in salePeriods" :key="period.value" :value="period.value" :selected="selectedSalePeriod == period.value">{{ period.label }}</option>
                                    </select>
                                </div>
                            </div>
                            <table class="table table-bordered table-striped">
                                <thead>
                                    <th>Produit/Plat</th>
                                    <th>Total</th>
                                </thead>
                                <tbody>
                                    <tr v-for="sale in sales" :key="`${sale.libelle_produit || sale.libelle_menu}_${sale.total}`">
                                        <td>{{ sale.libelle_produit || sale.libelle_menu }}</td>
                                        <td>{{ moneyFormat(sale.total) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            </div>

        </div>
        <!-- /.container-fluid -->

    </div>
    <!-- End of Main Content -->
</template>

<script>
import helpers from '@/services/common'
import { ROOM_STATUS } from '@/services/consts.js'
import DashboardChart from './DashboardChart.vue'
import _ from 'lodash'


export default {
    name: 'DashboardContent',
    data: function () {
        return {
            devise: 'FCFA',
            chartType: 'bar',
            roomStatus: ROOM_STATUS,
            salePeriods: [
                { label: 'Ce mois', value: 'MONTH' },
                { label: 'Cette année', value: 'YEAR' }
            ],
            selectedSalePeriod: 'YEAR'
        }
    },
    props: {
        service: Object,
        serviceName: String
    },
    computed: {
        roomGroups: function () {
            return this.service?.roomTypes
        },
        chartData: function () {
            const data = this.service?.lastMonthsIncome
            if (!data) {
                return null;
            }
            const parsedData = _.transform(data, (result, element) => {
                result.labels.push(element.monthName)
                result.values.push(element.amount)
            }, {labels: [], values: []})
            return parsedData;
        },
        sales: function () {
            return this.selectedSalePeriod == 'YEAR' ? this.service?.sales.yearSales : this.service?.sales.monthSales
        },
    },
    methods: {
        moneyFormat: function(number) {
            return `${helpers.numberFormat(number, 0, ' ')} ${this.devise}`
        },
        parseDate : (dateString) => {
            return helpers.parseDateString(dateString)
        },
        updatePeriod : function(event) {
            this.selectedSalePeriod = event.target.value
        }
    },
    components: {
        DashboardChart
    }
}
</script>

<style scoped>
    .room {
        margin-top: 5px;
        margin-bottom: 5px;
    }
</style>