<template>
    <div id="page-top">
        <div id="wrapper">

        <!-- Sidebar -->
        <ul class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

            <!-- Sidebar - Brand -->
            <a class="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
                <div class="sidebar-brand-icon">
                    <i class="fas fa-building"></i>
                </div>
                <div class="sidebar-brand-text mx-3">{{entityName}}</div>
            </a>

            <!-- Divider -->
            <hr class="sidebar-divider my-0">

            <!-- Nav Item - Dashboard -->
            <li class="nav-item">
                <a class="nav-link" href="#">
                    <i class="fas fa-fw fa-tachometer-alt"></i>
                    <span>Tableau de bord</span></a>
            </li>
            <hr class="sidebar-divider my-0">
            <li class="nav-item" :class="{ active: currentService == 'HOTEL' }">
                <a class="nav-link" href="#" @click="switchService('HOTEL')" >
                    <span>Hotel</span>
                </a>
            </li>
            <hr class="sidebar-divider my-0">
            <li class="nav-item" :class="{ active: currentService == 'RESTAURANT' }">
                <a class="nav-link" href="#" @click="switchService('RESTAURANT')">
                    <span>Restaurant</span>
                </a>
            </li>

            <!-- Divider -->
            <hr class="sidebar-divider">
            <div class="sidebar-heading">
                Dernière mise à jour
            </div>

            <!-- Nav Item - Pages Collapse Menu -->
            <li class="nav-item">
                <a class="nav-link" href="#" >
                    <span>{{ entity?.updated_at ? parseDate(entity.updated_at) : '' }}</span>
                </a>
            </li>

        </ul>
        <!-- End of Sidebar -->

        <!-- Content Wrapper -->
        <div id="content-wrapper" class="d-flex flex-column">

            <!-- Main Content -->
                <dashboard-content :service="service" :serviceName="currentService"/>
            <!-- End of Main Content -->

            <!-- Footer -->
            <footer class="sticky-footer bg-white">
                <div class="container my-auto">
                    <div class="copyright text-center my-auto">
                        <span>Copyright &copy; Hostman live 2022</span>
                    </div>
                </div>
            </footer>
            <!-- End of Footer -->

        </div>
        <!-- End of Content Wrapper -->

    </div>
    <!-- End of Page Wrapper -->

    <!-- Scroll to Top Button-->
    <a class="scroll-to-top rounded" href="#page-top">
        <i class="fas fa-angle-up"></i>
    </a>

    <!-- Logout Modal-->
    <div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                    <a class="btn btn-primary" href="login.html">Logout</a>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
import DashboardContent from './DashboardContent.vue'
import HttpService from '../services/httpService'
import helpers from '@/services/common'
import VModal from 'vue-js-modal'
import Vue from 'vue'
const LOOP_DURATION = 10 /* minutes */ * 60 * 1000; //milliseconds

Vue.use(VModal)

export default {
    name: 'DashBoard',
    data: function () {
        return {
            entityName: 'Complexe Le Tsar',
            entityCode: 'test-entity-code',
            loading: false,
            entity: null,
            currentService: 'HOTEL'
        }
    },
    methods: {
        getEntityData: async function() {
            this.loading = true
            try {
                const data = await HttpService.get('', { code: this.entityCode })
                if (data.entity) {
                    this.entity = data.entity
                    this.chartData = this.parseChartData(data.entity.hostel.lastMonthsIncome)
                }
            } catch (error) {
                //Nothing to do here
            } finally {
                console.log('Data fetched')
                this.loading = false
            }
        },
        switchService: function (service) {
            this.currentService = service
        },
        dataUpdateLoop: (instance) => {
            instance.getEntityData()
            setInterval(instance.getEntityData, LOOP_DURATION)
        },
        parseDate : (dateString) => {
            return helpers.parseDateString(dateString)
        }
    },
    mounted: function () {
       this.dataUpdateLoop(this)
    },
    computed: {
        service: function () {
            return this.currentService == 'HOTEL' ? this.entity?.hostel : this.entity?.restaurant
        }
    },
    components: {
        DashboardContent
    }
}
</script>

<style>
    @import './../assets/styles/sb-admin-2.css';
    .room {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .nav-item.active {
        background-color: #273c75;
    }
</style>